.custom-toast {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #6a1b9a; /* Violet border */
  background-color: #f9f9f9; /* Light background */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Gothic", sans-serif;
  width: 300px;
}

.custom-toast .icon {
  font-size: 40px;
  color: #6a1b9a; /* Violet color for icons */
}

.custom-toast .message {
  flex: 1;
  text-align: center;
  font-size: 16px;
  margin: 15px 0;
}

.custom-toast .action {
  margin-top: 10px;
}

.custom-toast .btn {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #6a1b9a; /* Violet background */
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
